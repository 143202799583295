import { motion } from "framer-motion";
import React from "react";
import styled from "styled-components";
import { hoverChange } from "../../functions/util";
import { animation } from "../../style/theme";
import Link from "../helpers/link";
import SvgLoader from "../svg/index";

const MenuOverlay = styled(motion.div)`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 500;
  color: ${({ theme }) => theme.colors.peach};
  background: ${({ theme }) => theme.colors.black};
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: ${({ theme }) => theme.layout.space[300]}
    ${({ theme }) => theme.layout.outerWrapperMobile}
    ${({ theme }) => theme.layout.space[100]};

  a {
    color: ${({ theme }) => theme.colors.peach};
  }
`;

const CloseButton = styled.button`
  cursor: pointer;
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  z-index: 500;
  background: transparent;
  padding: 0.25rem;
  outline: none !important;
  border: none !important;
  transition: transform ${({ theme }) => theme.animation.duration[300].css}
      ${({ theme }) => theme.animation.timingFunction.css},
    opacity ${({ theme }) => theme.animation.duration[100].css}
      ${({ theme }) => theme.animation.timingFunction.css} !important;

  &:hover {
    transform: scale(1.2);
  }

  path {
    fill: white;
  }
`;

const MenuRow = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  &.logo {
    @media ${({ theme }) => theme.device.tablet} {
      order: -1;
    }

    svg {
      width: 8rem;
    }
  }

  p {
    margin: 1.5rem 0 0;
    opacity: 0.5;
    max-width: 30em;
  }
`;

const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-transform: lowercase;

  a {
    text-decoration: none;
    transition: transform ${({ theme }) => theme.animation.duration[300].css}
        ${({ theme }) => theme.animation.timingFunction.css},
      color ${({ theme }) => theme.animation.duration[100].css}
        ${({ theme }) => theme.animation.timingFunction.css} !important;

    &:hover {
      transform: translateY(-0.1em);
    }

    &:active {
      transform: translateY(0.1em);
    }
  }
`;

export function NavMenu({ navData, showMenu, setShowMenu }) {
  return (
    <>
      <MenuOverlay
        initial={{ opacity: 0, scale: 1.05 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 1.05 }}
        transition={{
          ease: animation.timingFunction.js,
          duration: animation.duration[100].js,
        }}
      >
        <CloseButton
          aria-label="Close"
          onClick={() => setShowMenu(!showMenu)}
          onMouseEnter={() => hoverChange("link")}
          onMouseLeave={() => hoverChange(null)}
          className="nav--banner--close nav--banner--close-float"
        >
          <SvgLoader svg="X" fill="peach" />
        </CloseButton>
        <MenuRow className="links">
          <LinksWrapper>
            <Link
              to={"/"}
              onMouseUp={() => setShowMenu(!showMenu)}
              className="h1 serif"
            >
              Home
            </Link>
            {navData.map(({ data, uid }, i) => {
              const pathUrl = uid === "home" ? `/` : `/${uid}`;
              return (
                <Link
                  key={i}
                  to={pathUrl}
                  onMouseUp={() => setShowMenu(!showMenu)}
                  className="h1 serif"
                >
                  {data.title}
                </Link>
              );
            })}
          </LinksWrapper>
        </MenuRow>
        <MenuRow className="logo">
          <SvgLoader svg="Logo" color="black" />
          {/* <p className="p-small">
            All work is Copyright 2021 {siteMetadata?.author}, unless otherwise
            noted. Site by{" "}
            <LinkExternal href="https://john.design">John Choura</LinkExternal>.
          </p> */}
        </MenuRow>
      </MenuOverlay>
    </>
  );
}
