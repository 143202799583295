import {
  LazyMotion,
  domAnimation,
  m,
  useMotionValue,
  useReducedMotion,
} from "framer-motion";
import React, { useEffect } from "react";
import styled from "styled-components";
import SvgLoader from "./svg";

export function CursorShape({ children, ...rest }) {
  return (
    <m.div initial={false} {...rest}>
      <SvgLoader svg="ArrowRight" className="cursor-arrow" />
    </m.div>
  );
}

export default function Cursor() {
  const cursorX = useMotionValue(1000);
  const cursorY = useMotionValue(1000);
  const reducemotion = useReducedMotion();

  useEffect(() => {
    if (typeof document !== `undefined` && typeof window !== `undefined`) {
      const moveCursor = (e) => {
        const elWidth = 32;
        const elHeight = 32;

        cursorX.set(e.clientX - elWidth / 2);
        cursorY.set(e.clientY - elHeight / 2);
      };
      window.addEventListener("mousemove", moveCursor);
      return () => {
        window.removeEventListener("mousemove", moveCursor);
      };
    }
  }, [cursorX, cursorY]);

  return (
    <LazyMotion features={domAnimation}>
      <CursorWrap
        id="cursor-element"
        data-reduce-motion={reducemotion}
        style={{
          translateX: cursorX,
          translateY: cursorY,
        }}
      />
    </LazyMotion>
  );
}

const CursorWrap = styled(CursorShape)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  mix-blend-mode: difference;
  left: 0;
  top: 0;
  pointer-events: none;
  z-index: 9999;
  transition: opacity ${({ theme }) => theme.animation.duration[100].css}
    ${({ theme }) => theme.animation.timingFunction.css};

  @media (hover: none) {
    visibility: hidden;
    display: none;
  }

  &:after {
    content: var(--content, "");
    position: absolute;
    top: 2.5rem;
    left: 2.5rem;
    font-size: ${({ theme }) => theme.fonts.h5};
    opacity: 0;
    transition: opacity ${({ theme }) => theme.animation.duration[200].css}
        ${({ theme }) => theme.animation.timingFunction.css},
      transform ${({ theme }) => theme.animation.duration[100].css}
        ${({ theme }) => theme.animation.timingFunction.css};
    transform: translate3d(0, 1rem, 0);
    will-change: transform, opacity;
  }

  .cursor-arrow {
    transform: translate3d(0.3rem, 0.65rem, 0) rotate(-120deg);
    transform-origin: center center;
    transition: transform ${({ theme }) => theme.animation.duration[100].css}
        ${({ theme }) => theme.animation.timingFunction.css},
      opacity ${({ theme }) => theme.animation.duration[100].css}
        ${({ theme }) => theme.animation.timingFunction.css};
  }
`;
