import * as React from "react";

/**
 * Local Components
 */
// import Footer from "../components/globals/footer";
import Head from "../components/globals/head";
import Nav from "../components/globals/nav";

/**
 * Local styles
 */
import Cursor from "../components/cursor";
import { PageWrapper } from "../components/globals/wrappers";
import { hoverChange } from "../functions/util";

/**
 * Layout Component
 *
 * @param {Object} props
 * @param {any} props.children
 * @param {Object} props.pageContext
 * @returns page layouts
 */
export default function Layout({ children, ...rest }) {
  React.useEffect(() => {
    hoverChange("");
    return () => hoverChange("");
  }, []);

  // Ensure rest is always an object
  const context = rest || {};

  return (
    <>
      <Head context={context || {}} />
      <Nav context={context || {}} />
      <PageWrapper>
        <main id="main">{children}</main>
      </PageWrapper>
      {/* <Footer context={context || {}} /> */}
      <Cursor />
      {/* <Awwwards /> */}
    </>
  );
}
