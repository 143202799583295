import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      width={54}
      height={54}
      viewBox="0 0 54 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M27 0C11.647 0 0 9.53 0 21.882v9.353C0 45 10.588 54 27 54c15.353 0 27-9.53 27-21.882v-9.353C54 9 43.412 0 27 0zm0 3.53c13.235 0 22.059 6.529 23.294 16.588-4.235-6.177-12-10.06-21.353-10.06-9.353 0-16.412 5.118-16.412 11.824C12.53 28.06 18 33 25.412 33.706H27c4.941 0 8.824-2.118 9.882-5.294a6.363 6.363 0 011.06 3.53c0 4.764-5.471 8.293-12.883 8.293-12.53 0-21.53-7.764-21.53-18.53C3.353 11.472 13.59 3.53 27 3.53zm.882 20.294c3.353.176 5.647 1.764 5.647 3.352 0 1.589-2.823 3.353-6.705 3.353h-.883c-3.53-.176-5.823-1.94-5.823-3.353 0-1.588 2.823-3.352 6.706-3.352h1.058zm-1.058 26.823c-13.236 0-22.06-6.53-23.295-16.765 4.236 6.177 12 10.06 21.353 10.06 9.353 0 16.412-5.118 16.412-11.824 0-6-5.117-10.765-12.176-11.647-.706-.177-1.412-.177-2.118-.177-4.941 0-8.823 2.118-9.882 5.294a6.363 6.363 0 01-1.06-3.53c0-4.764 5.471-8.293 12.883-8.293 12.53 0 21.53 7.764 21.53 18.53 0 10.41-10.236 18.352-23.647 18.352z"
        fill="#111"
      />
    </svg>
  );
}

export default SvgComponent;
